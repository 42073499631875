import React from 'react'
import numeral from 'numeral'
import {RouteComponentProps} from 'react-router'
import {ObjectUtil} from 'helper-util'
import * as Sentry from '@sentry/browser'
import {LicenseManager} from 'ag-grid-enterprise'

import WebSocketNotification from '../features/webSocket/webSocketContainer'
import ToastNotification from '../features/toastNotification/toastNotificationContainer'
import NavigationBar from '../features/navigationBar/navigationBarContainer'
import ErrorBoundary from '../pages/errorHandler/errorBoundary'
import ErrorHandler from '../pages/errorHandler/errorContainer'
import Routes from '../routes/routes'
import {paths} from '../routes/routesConfig'
import {history} from '../store/mainStore'
import './app.scss'
import {Props} from './appModel'
import {activeClientConfig, getUser, isAuthenticated} from '../services/authService'
import {checkFeaturePermission, getThemeFromLs, setFlexMonsterTheme} from '../utils/featureUtils'
import {apiConfig} from '../config/apiConfig'
import {permission} from '../constants/permission'
import {decryptURL} from '../pages/abcPortfolio/abcPortfolioHelper'
import {checkConfigDetailsInStorage, genericLogout, isRestrictedEntryPermission} from './appHelper'
import {isReadWritePortfolioPermission} from './appHelper'
import {setMainContentWidth} from './appHelper'
import {getStringFromStorage, setObjectInStorage, setStringInStorage} from '../services/storageService'
import {initializeAmplify} from '../utils/amplify'
import {setAuthExpiryTime} from '../utils/auth'
import {CLIENT_CONSTANTS, Config} from '../constants/clientConfig'

export default class App extends React.Component<Props & RouteComponentProps, any> {
	autoLogout: any
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			token: Boolean(getStringFromStorage('authTokenKey')),
		}
		LicenseManager.setLicenseKey(apiConfig.agGridKey)

		history.listen(() => this.toggleNavbarType(false))
	}

	componentDidMount() {
		const {startLoader, authLogout} = this.props
		const {getPortfolioAllEntries, getRestrictedEntries, getPortfolioStatus, setDarkTheme} = this.props
		const {setSuggestions, getLocateNotifications, getLocateClientNotifications} = this.props
		const isLoggedIn = isAuthenticated()
		const clientConfig = getStringFromStorage(activeClientConfig)

		const user = getUser()
		if (user && user.email && isLoggedIn) {
			// getActiveMenu()
			if (isReadWritePortfolioPermission()) {
				getPortfolioAllEntries()
				getPortfolioStatus()
			}
			if (isRestrictedEntryPermission()) {
				getRestrictedEntries()
			}
			Sentry.configureScope(function (scope) {
				scope.setUser({username: user.email})
			})
			if ([CLIENT_CONSTANTS.FINOPTSYS, CLIENT_CONSTANTS.DH].includes(clientConfig)) {
				checkFeaturePermission(permission.LOCATE_PROVIDER) && getLocateNotifications()
				checkFeaturePermission(permission.LOCATE_SEEKER) && getLocateClientNotifications()
			}
		} else {
			// authenticateRoutesAndLogout(history)
		}

		if (startLoader) startLoader('started')

		const theme = getThemeFromLs()
		setDarkTheme(theme === 'dark')
		setFlexMonsterTheme(theme)
		document.getElementsByTagName('html')[0].setAttribute('data-theme', theme)

		const suggestion = localStorage.getItem('suggestions')
		setSuggestions(suggestion === 'show')

		this.switchLocale()

		const {amplifyConfig, tenant} = checkConfigDetailsInStorage()
		if (!ObjectUtil.isEmpty(amplifyConfig)) {
			initializeAmplify(amplifyConfig, tenant, false)
			setObjectInStorage('amplifyConfig', amplifyConfig)
			setStringInStorage('tenant', tenant)
		} else {
			this.setState({token: true, loading: false})
			// handleBrowserRefreshCognitoActivity(history, authLogout)
		}
		setAuthExpiryTime(null, history, (resolved, token) => {
			if (resolved) {
				this.setState({token, loading: false})
			} else {
				genericLogout(authLogout, history)
			}
		})

		if (clientConfig) {
			Config.setActiveConfig = clientConfig
		}
	}

	switchLocale = () => {
		numeral.locale('en_US')
	}

	toggleNavbarType = isWide => {
		setMainContentWidth(isWide)
		this.props.setNavbarType(isWide)
	}

	handleMouseMove = () => {
		clearInterval(this.autoLogout)
		this.initializeAutoLogout()
	}

	initializeAutoLogout = () => {
		const {authLogout} = this.props
		this.autoLogout = setTimeout(() => {
			const user = getUser()
			if (user && user.email) {
				genericLogout(authLogout, history)
			}
		}, 900000)
	}

	render() {
		const {location: {pathname: activePath} = {pathname: ''}} = history
		const {errorMessage, wideNavbar} = this.props
		const filterData = decryptURL(this.props.location.search)
		const {securityId = null, filterValue = null, cpty = null, hideNavigation = null} = filterData
		const excludedPaths = new Set([
			paths.login,
			paths.changePassword,
			paths.forgotPassword,
			paths.resetPassword.split('/')[1],
			paths.default,
			paths.dummyWidget,
			paths.collateralOptWidget1,
			paths.collateralOptWidget2,
			paths.marginAttributionWidget,
			paths.tenant,
		])
		const isEncryptedABCPortfolioTestPage =
			activePath.includes(paths.abcPortfolioTest) && (filterData.isEncrypted || filterValue)

		const showNavPanel =
			!excludedPaths.has(activePath) && !isEncryptedABCPortfolioTestPage && !securityId && !hideNavigation && !cpty

		return (
			<>
				{!activePath.includes(paths.login) && activePath !== paths.default && (
					<>
						<ToastNotification />
						<WebSocketNotification />
					</>
				)}
				{showNavPanel && (
					<NavigationBar toggleNavbarType={this.toggleNavbarType} wideNavbar={wideNavbar} navBarType='main' />
				)}
				<div className='container-fluid px-0 pb-0'>
					<ErrorBoundary>
						{errorMessage && <ErrorHandler />}
						<Routes activePath={activePath} />
					</ErrorBoundary>
				</div>
			</>
		)
	}
}

