import {buildQuery} from 'axios-graphql-builder'

export const tradeDetailsEntries = {
	actions: null,
	activityType: null,
	allInRate: null,
	assetClassification: null,
	assetMaturityDate: null,
	assetSubType: null,
	assetType: null,
	availabilitiesContribution: {
		amount: null,
	},
	availableQuantity: null,
	balanceSheetContribution: {
		amount: null,
		currency: null,
	},
	baseNotional: {
		amount: null,
		currency: null,
	},
	contractValue: {
		amount: null,
		currency: null,
	},
	bbId: null,
	book: null,
	borrowFee: null,
	buySellInd: null,
	cash: {
		amount: null,
		currency: null,
	},
	collateral: {
		amount: null,
		currency: null,
	},
	collateralType: null,
	counterParty: null,
	createdBy: null,
	createdDate: null,
	cusip: null,
	endDate: null,
	exchangeRate: null,
	fitchRating: null,
	fund: null,
	// fxRate: null,
	haircut: null,
	hqla: null,
	id: null,
	inefficiencies: {
		inefficiency: null,
		abbr: null,
		displayName: null,
	},
	index: null,
	isin: null,
	legalEntity: null,
	loanFee: null,
	maturityDate: null,
	maturityType: null,
	moodyRating: null,
	needsContribution: {
		amount: null,
	},
	needQuantity: null,
	notional: {
		amount: null,
		currency: null,
	},
	originalTradeType: null,
	partial: null,
	poolFactor: null,
	portfolioEntryType: null,
	portfolioEntryTypeOption: null,
	price: {
		amount: null,
		currency: null,
	},
	priceMultiplier: null,
	originalNotional: null,
	quantity: null,
	rebate: null,
	recallInefficiencyContribution: {
		amount: null,
	},
	referenceRate: null,
	returnInefficiencyContribution: {
		amount: null,
	},
	ric: null,
	sector: null,
	securityId: null,
	securityIdType: null,
	settlementDate: null,
	snpRating: null,
	sourceSystem: null,
	sourceSystemTradeId: null,
	spread: null,
	startDate: null,
	tenor: null,
	termDate: null,
	ticker: null,
	tradeDate: null,
	tradeType: null,
}

const all = {
	query: {
		portfolio: {
			all: {
				entries: tradeDetailsEntries,
			},
			longTrades: {
				entries: tradeDetailsEntries,
			},
			shortTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}
const inefficiencies = {
	query: {
		portfolio: {
			inefficiencies: {
				entries: tradeDetailsEntries,
			},
			recallInefficiencies: {
				entries: tradeDetailsEntries,
			},
			returnInefficiencies: {
				entries: tradeDetailsEntries,
			},
		},
	},
}
const matureTrades = {
	query: {
		portfolio: {
			matureTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const matureSblTrades = {
	query: {
		sblDashboard: {
			matureTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const matureRepoTrades = {
	query: {
		repoDashboard: {
			matureTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const openTrades = {
	query: {
		portfolio: {
			openTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const openSblTrades = {
	query: {
		sblDashboard: {
			openTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const openRepoTrades = {
	query: {
		repoDashboard: {
			openTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}
const boxes = {
	query: {
		portfolio: {
			boxes: {
				entries: tradeDetailsEntries,
			},
			availabilities: {
				entries: tradeDetailsEntries,
			},
			needs: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const repoBoxes = {
	query: {
		repoDashboard: {
			boxes: {
				entries: tradeDetailsEntries,
			},
			availabilities: {
				entries: tradeDetailsEntries,
			},
			needs: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const allQuery = buildQuery(all)
const inefficienciesQuery = buildQuery(inefficiencies)
const matureTradesQuery = buildQuery(matureTrades)
const openTradesQuery = buildQuery(openTrades)
const boxesQuery = buildQuery(boxes)
const repoBoxesQuery = buildQuery(repoBoxes)
const openSblTradesQuery = buildQuery(openSblTrades)
const matureSblTradesQuery = buildQuery(matureSblTrades)
const openRepoTradesQuery = buildQuery(openRepoTrades)
const matureRepoTradesQuery = buildQuery(matureRepoTrades)

export const tradeDetailsQuery = {
	inefficiencies: inefficienciesQuery,
	recallInefficiencies: inefficienciesQuery,
	returnInefficiencies: inefficienciesQuery,
	matureTrades: matureTradesQuery,
	openTrades: openTradesQuery,
	all: allQuery,
	longTrades: allQuery,
	shortTrades: allQuery,
	boxes: boxesQuery,
	availabilities: boxesQuery,
	needs: boxesQuery,
}

export const repoTradeDetailsQuery = {
	inefficiencies: inefficienciesQuery,
	recallInefficiencies: inefficienciesQuery,
	returnInefficiencies: inefficienciesQuery,
	matureTrades: matureRepoTradesQuery,
	openTrades: openRepoTradesQuery,
	all: allQuery,
	longTrades: allQuery,
	shortTrades: allQuery,
	boxes: repoBoxesQuery,
	availabilities: repoBoxesQuery,
	needs: repoBoxesQuery,
}

export const sblTradeDetailsQuery = {
	inefficiencies: inefficienciesQuery,
	recallInefficiencies: inefficienciesQuery,
	returnInefficiencies: inefficienciesQuery,
	matureTrades: matureSblTradesQuery,
	openTrades: openSblTradesQuery,
	all: allQuery,
	longTrades: allQuery,
	shortTrades: allQuery,
	boxes: boxesQuery,
	availabilities: boxesQuery,
	needs: boxesQuery,
}
